import {logger} from '@mol-fe/mol-fe-client-logger';

const socialClick = function (...args) {
  const DMS = window.DMS;

  if (DMS && DMS.socialClick) {
    DMS.socialClick(...args);
  } else {
    logger.warn('DMS.socialClick is not available');
  }
};

export default socialClick;
