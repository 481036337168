/* eslint-disable import/prefer-default-export */
import {logger} from '@mol-fe/mol-fe-client-logger';
import {later} from '@mol-fe/mol-fe-async';
import SocialSharing from './SocialSharing';
import {setDataTrackModuleOnFactbox} from './trackModule';
import {initialiseReadMore} from './ReadMore';
// eslint-disable-next-line import/no-unassigned-import
import './permabox/styles.scss';

const loaded = (async () => {
  try {
    await later('DOM_READY');

    const factboxes = Array.from(document.querySelectorAll('.mol-factbox[data-version="2"]'));

    factboxes
      .forEach((element) => {
        element.molFeFactboxSocialSharing = new SocialSharing(element);
        initialiseReadMore(element);
        setDataTrackModuleOnFactbox(element);
      });

    return factboxes;
  } catch (error) {
    logger.error('Error during mol-fe-factbox initialisation: ', error);

    return null;
  }
})();

export {loaded};
