const lazyLoadComplete = function (img) {
  const lazyLoadSource = img.getAttribute('data-src');

  return !lazyLoadSource || img.src === lazyLoadSource;
};

const waitForImg = function (img) {
  return new Promise((resolve, reject) => {
    if (!img.src) {
      reject(new Error('No image source'));
    } else if (img.complete && lazyLoadComplete(img)) {
      resolve();
    } else {
      img.addEventListener('load', () => {
        if (lazyLoadComplete(img)) {
          resolve();
        }
      });
      img.addEventListener('error', reject);
    }
  });
};

export default waitForImg;
