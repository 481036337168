import {logger} from '@mol-fe/mol-fe-client-logger';
import htmlToElement from '../helpers/htmlToElement';
import replaceClass from '../helpers/replaceClass';
import debounce from '../helpers/debounce';
import Handler from './Handler';
import styles from './styles.scss';
import template from './template';

const MIN_HEIGHT = 445;
const MIN_WIDTH = 375;

class SocialShareOverlay {
  constructor ({parentElement, nthPosition, config}) {
    if (parentElement.molFeSocialShareOverlay) {
      return parentElement.molFeSocialShareOverlay;
    }

    parentElement.molFeSocialShareOverlay = this;
    this.nthPosition = nthPosition;
    this.parentElement = parentElement;
    this.element = htmlToElement(template({
      reverseIcon: config.isChannelShare
    }));
    this.handler = new Handler(this, config);
    this.config = config;
    this._init();
  }

  _init () {
    if (!this.parentElement.style.position) {
      this.parentElement.style.position = 'relative';
    }

    if (this.config.isChannelShare) {
      this.parentElement.classList.add('first-triple-preview-links-container');
      this.element.style.display = 'none';
      this.parentElement.addEventListener('click', (event) => {
        if (event.target && event.target.closest(`.${styles.container}`) && !(event.target.href && event.target.href.startsWith('mailto:'))) {
          event.preventDefault();
          this.adjustDirection().expand();
        }
      });
    }

    this.parentElement.appendChild(this.element);
    this.parentElement.addEventListener('mouseenter', () => this.adjustDirection().expand());
    this.parentElement.addEventListener('mouseleave', () => this.adjustDirection().contract());
    this.adjustDirection();
    this._initEvents();
  }

  adjustDirection () {
    if (this.config.isChannelShare) {
      this.makeVertical();

      return this;
    }

    if (this.parentElement.clientHeight < MIN_HEIGHT) {
      if (this.parentElement.clientWidth >= MIN_WIDTH) {
        this.makeHorizontal();
        this.element.style.display = '';
      } else {
        this.element.style.display = 'none';
      }
    } else {
      this.makeVertical();
      this.element.style.display = '';
    }

    return this;
  }

  makeVertical () {
    replaceClass(this.element, styles.vertical, styles.horizontal);
  }

  makeHorizontal () {
    replaceClass(this.element, styles.horizontal, styles.vertical);
  }

  expand () {
    this.element.classList.add(styles.expanded);
    this.handler._apply();
  }

  contract () {
    this.element.classList.remove(styles.expanded);
  }

  _initEvents () {
    if (this.config.isChannelShare) {
      this.parentElement.addEventListener('click', (event) => {
        if (event.target && event.target.closest('[data-social-scope]')) {
          const isEmailButton = event.target.tagName === 'A' &&
            event.target.href &&
            event.target.href.startsWith('mailto:') &&
            event.target.closest('[data-social-scope]').dataset.socialScope === 'email';

          if (!isEmailButton) {
            event.preventDefault();
          }
        }
      });
    }

    this.element.addEventListener('click', debounce((event) => {
      const element = event.target.closest('[data-social-scope]');

      if (element) {
        const socialScope = element.getAttribute('data-social-scope');

        if (typeof this.handler[socialScope] === 'function') {
          this.handler[socialScope](event);
        } else {
          logger.error('Wrong handler provided');
        }
      }
    }, 200));
  }
}

export default SocialShareOverlay;
