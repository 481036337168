import {logger} from '@mol-fe/mol-fe-client-logger';

const postToTWTTR = function (...args) {
  const DMS = window.DMS;

  if (DMS && DMS.Twitter && DMS.Twitter.postToTWTTR) {
    DMS.Twitter.postToTWTTR(...args);
  } else {
    logger.warn('DMS.Twitter.postToTWTTR is not available');
  }
};

export default postToTWTTR;
