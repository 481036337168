import styles from './styles.scss';

export default ({reverseIcon = false} = {}) => {
  const shareIcon = `<li class="${styles.linkItem} ${styles.shareIcon}"></li>`;

  return `
  <div class="${styles.container} ${styles.vertical}">
    <ul class="${styles.linksHolder}">
      ${reverseIcon ? shareIcon : ''}
      <li class="${styles.linkItem}" data-social-scope="facebook"></li>
      <li class="${styles.linkItem}" data-social-scope="twitter"></li>
      <li class="${styles.linkItem}" data-social-scope="pinterest"></li>
      <li class="${styles.linkItem}" data-social-scope="email"></li>
      <li class="${styles.linkItem}" data-social-scope="link">
        <div class="${styles.copyLinkBox}">
          <input type="text" value="" readonly="readonly"/>
          <div>Copy link to paste in your message</div>
        </div>
      </li>
      ${reverseIcon ? '' : shareIcon}
    </ul>
  </div>
  `;
};
