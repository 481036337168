import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';

const createHref = (subject, body) => `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

const getMailToHref = (subject, body) => {
  let newSubject = subject;
  let newBody = body;
  let href = createHref(newSubject, newBody);

  // truncate headline to 100 characters if still too long
  if (href.length > 2000) {
    newSubject = `${newSubject.substring(0, 97).trimEnd()}...`;
    href = createHref(newSubject, newBody);
  }

  if (href.length > 2000) {
    newBody = getPageCriteria().articleShortURL || window.location.href;
    href = createHref(newSubject, newBody);
  }

  return href;
};

export default getMailToHref;
