import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {getMessageCountUrl} from '@mol-fe/mol-fe-openweb-core';
import {beautifyValue, pollForComments, POLLING_INTERVAL} from './commentsCountPolling';
import {getFromStorage, setToStorage} from './storage';

const fireTracking = (eventName) => {
  try {
    pageEvents.publish(eventName);
  } catch (error) {
    logger.error(`[mol-fe-openweb-comments] Unable to publish tracking event ${eventName}`, error);
  }
};

const isPaywallEligibleCheck = () => window.getIsPaywallEligible && window.getIsPaywallEligible();
const isPaywallSubscriberCheck = () => window.getIsPaywallSubscriber && window.getIsPaywallSubscriber();

const handleDismiss = (store, observer, floatingButton) => {
  setToStorage('floating', {
    ...store || {},
    dismissedDate: Date.now()
  });

  fireTracking(pageEvents.OPENWEB_FLOATING_DISMISS);

  if (observer) {
    observer.disconnect();
  }

  if (floatingButton) {
    floatingButton.remove();
  }
};

const EXPIRE_DISMISS = 14 * 24 * 60 * 60 * 1000;

export const initFloatingButton = ({onClick}) => {
  const isPaywallEligible = isPaywallEligibleCheck();
  const isPaywallSubscriber = isPaywallSubscriberCheck();
  const {isPaywalled} = getPageCriteria();

  if (isPaywalled && isPaywallEligible && !isPaywallSubscriber) {
    return;
  }

  const store = getFromStorage('floating');
  const now = Date.now();

  if (store && store.dismissedDate && now - store.dismissedDate < EXPIRE_DISMISS) {
    return;
  }

  const {articleId} = getPageMetadata();
  const floatingButton = document.createElement('div');
  const commentCountElement = document.querySelector('.comments-count > a > em');
  const initialCommentCount = commentCountElement &&
    commentCountElement.textContent &&
    beautifyValue(parseInt(commentCountElement.textContent, 10)) || '';

  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      const elementType = entry.target.tagName.toLowerCase() === 'h1' ? 'headline' : 'article';

      if (entry.isIntersecting) {
        floatingButton.classList.add(`comments-floating-cta__${elementType}-visible`);
      } else {
        floatingButton.classList.remove(`comments-floating-cta__${elementType}-visible`);
      }
    }
  });

  floatingButton.classList.add('comments-floating-cta');
  floatingButton.innerHTML = `
    <div class="comments-floating-cta__main">
      <div class="comments-floating-cta__icon"></div>
      <div class="comments-floating-cta__count">${initialCommentCount}</div>
    </div>
    <div class="comments-floating-cta__dismiss"></div>
  `;

  const dismissButton = floatingButton.querySelector('.comments-floating-cta__dismiss');
  const mainButton = floatingButton.querySelector('.comments-floating-cta__main');
  const counterElement = floatingButton.querySelector('.comments-floating-cta__count');

  mainButton.addEventListener('click', () => {
    onClick();
    fireTracking(pageEvents.OPENWEB_FLOATING_CLICK);
  });

  dismissButton.addEventListener('click', () => handleDismiss(store, observer, floatingButton));

  document.body.appendChild(floatingButton);

  const headline = document.querySelector('.scrollable-content h1');
  const articleBody = document.querySelector('.scrollable-content > article');

  observer.observe(headline);
  observer.observe(articleBody);

  const url = getMessageCountUrl(articleId);

  pollForComments(
    url,
    articleId,
    counterElement,
    POLLING_INTERVAL
  );
};
