/* eslint-disable import/prefer-default-export */
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getConfig} from '@mol-fe/mol-fe-components';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {later} from '@mol-fe/mol-fe-async';
import SocialShareOverlay from './SocialShareOverlay';

const selector = 'img[id^="i-"], [data-mol-fe-social-share-overlay]';

const getQualifyingParentElement = function (element) {
  let parentElement;

  parentElement = element.parentNode;
  if (parentElement.className.indexOf('image-wrap') !== -1) {
    parentElement = parentElement.parentNode;
  }

  return parentElement;
};

const filterGalleryExcludedElements = (element) => !element.closest('.mol-product');

const loaded = Promise.all([
  getConfig('molFeSocialShareOverlay'),
  later('DOM_READY')
])
  .then(([config]) => {
    const result = [];

    if (!getPageCriteria().isMobile) {
      const elements = document.querySelectorAll(selector);

      Array.from(elements).filter(filterGalleryExcludedElements).forEach((element, index) => {
        try {
          result.push(new SocialShareOverlay({
            config,
            nthPosition: index,
            parentElement: getQualifyingParentElement(element)
          }));
        } catch (err) {
          logger.error('Cannot init mol-fe-social-share-overlay', err, element);
        }
      });
    }

    return result;
  })
  .catch((error) => {
    logger.error('Error during mol-fe-social-share-overlay initialisation: ', error);
  });

const initForChannel = async () => {
  try {
    if (getPageCriteria().pageType !== 'channel' || getPageMetadata().renderPlatform !== 'desktop') {
      return null;
    }

    await later('DOM_READY');

    const firstArticlePreview = document.querySelector('#content .article');
    const firstTriplePreview = document.querySelector('#content .gamma .article-tri-headline');
    const imageElement = firstTriplePreview && firstTriplePreview.querySelector('img');

    if (!firstTriplePreview || firstArticlePreview !== firstTriplePreview || !imageElement) {
      return null;
    }

    const container = imageElement.parentNode;

    if (container.tagName !== 'A' || !container.href) {
      return null;
    }

    return new SocialShareOverlay({
      config: {
        articleFullUrl: container.href,
        isChannelShare: true
      },
      nthPosition: 0,
      parentElement: container
    });
  } catch (error) {
    logger.error('Error during mol-fe-social-share-overlay channel initialisation: ', error);
  }

  return null;
};

initForChannel().catch();

export {loaded, SocialShareOverlay};
