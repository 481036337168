import postToFB from '../../lib/postToFB';
import postToTWTTR from '../../lib/postToTWTTR';
import postToPinterest from '../../lib/postToPinterest';

const postTo = {
  facebook: postToFB,
  pinterest: postToPinterest,
  twitter: postToTWTTR
};

export default postTo;
