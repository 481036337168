/* eslint-disable complexity, import/max-dependencies */
import {getConfig} from '@mol-fe/mol-fe-components';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import articleSocialTitle from '../helpers/articleSocialTitle';
import pageUrl from '../helpers/pageUrl';
import waitForImg from '../helpers/waitForImg';
import socialClick from '../lib/socialClick';
import twitterVia from '../lib/twitterVia';
import postTo from './helpers/postTo';
import getMailToHref from './helpers/getMailToHref';
import styles from './styles.scss';

const findAnyImage = function (element) {
  return element.tagName.toLowerCase() === 'img' ? element : element.getElementsByTagName('img')[0];
};

const getElementLink = function (element, url) {
  const id = element.id;

  return url + (id ? '#' + id : '');
};

const addItoToUrl = (url, ito) => {
  if (url.includes('#')) {
    if (url.includes('?')) {
      return url.replace(/#/, `&ito=${ito}#`);
    }

    return url.replace(/#/, `?ito=${ito}#`);
  }

  if (url.includes('?')) {
    return `${url}&ito=${ito}`;
  }

  return `${url}?ito=${ito}`;
};

class Handler {
  constructor (socialShareOverlay, {articleShortUrl, articleFullUrl, isChannelShare = false}) {
    this.isChannelShare = isChannelShare;
    this.socialShareOverlay = socialShareOverlay;
    this.img = findAnyImage(this.socialShareOverlay.parentElement) || {};
    this.src = this.img.getAttribute('data-src') || this.img.src || '';
    this.alt = this.img.alt || '';
    this.fullUrl = getElementLink(this.img, articleFullUrl || pageUrl);
    this.shortUrl = getElementLink(this.img, articleShortUrl || articleFullUrl || pageUrl);
    this.imgOptions = {
      id: isChannelShare ? 'main-channel-preview' : this.img.id,
      nthPosition: this.socialShareOverlay.nthPosition
    };
    this.trackPlacement = isChannelShare ? 'image-share-home' : 'image-share';
    this.itoSuffix = '_share_article-image-share';

    if (isChannelShare) {
      const {channel, subchannel} = getPageCriteria();

      this.itoSuffix = `_share_article-image-share-${channel}${channel === subchannel ? '' : `-${subchannel}`}`;
    }

    this._initLinkBoxElement();
    this._initEmailElement();

    if (!isChannelShare) {
      waitForImg(this.img)
        .then(() => this.socialShareOverlay.adjustDirection())
        .catch(() => {});
    }
  }

  facebook (event) {
    event.preventDefault();
    this._reset();
    const shareObject = JSON.stringify({
      description: this.alt,
      link: addItoToUrl(this.fullUrl, `facebook${this.itoSuffix}`),
      picture: this.src,

      // eslint-disable-next-line id-match
      redirect_uri: 'https://i.dailymail.co.uk/i/pix/facebook/close.html'
    });

    postTo.facebook(this.trackPlacement, shareObject, this.imgOptions);
  }

  twitter (event) {
    event.preventDefault();
    this._reset();
    const urlToShare = addItoToUrl(this.shortUrl, `pinterest${this.itoSuffix}`);
    const shareObject = {
      text: this.alt,
      url: urlToShare,
      via: twitterVia
    };

    postTo.twitter(this.trackPlacement, shareObject, this.imgOptions);
  }

  pinterest (event) {
    event.preventDefault();
    this._reset();
    const urlToShare = addItoToUrl(this.shortUrl, `pinterest${this.itoSuffix}`);
    const shareObject = {
      description: this.alt.substring(0, 90) + '...',
      media: this.src,
      type: 'image',
      url: urlToShare,
      via: twitterVia
    };

    if (this.isChannelShare) {
      shareObject.forcedUrl = urlToShare;
    }

    postTo.pinterest(this.trackPlacement, shareObject, this.imgOptions);
  }

  _linkBoxShow () {
    this.socialShareOverlay.element.classList.add(styles.copyLinkBoxOn);
    this.linkBoxInputElement.focus();
    this.linkBoxInputElement.select();
  }

  link (event) {
    event.preventDefault();
    const classList = this.socialShareOverlay.element.classList;

    if (classList.contains(styles.copyLinkBoxOn) && !event.target.closest(`.${styles.copyLinkBox}`)) {
      classList.remove(styles.copyLinkBoxOn);
    } else {
      socialClick('link', this.trackPlacement, this.imgOptions);
      this._linkBoxShow();
    }
  }

  email () {
    socialClick('email', this.trackPlacement, this.imgOptions);
    this._reset();
  }

  _apply () {
    if (this.socialShareOverlay.element.classList.contains(styles.copyLinkBoxOn)) {
      this._linkBoxShow();
    }
  }

  _reset () {
    this.socialShareOverlay.element.classList.remove(styles.copyLinkBoxOn);
  }

  _initLinkBoxElement () {
    if (!this.linkBoxInputElement) {
      this.linkBoxInputElement = this.socialShareOverlay.element.querySelector(`.${styles.copyLinkBox} input`);
      this.linkBoxInputElement.setAttribute('value', addItoToUrl(this.fullUrl, `link${this.itoSuffix}`));
    }
  }

  _initEmailElement () {
    if (!this.emailElement) {
      const subject = `Look at this: ${articleSocialTitle}`;
      const body = [
        this.alt,
        addItoToUrl(this.isChannelShare ? this.shortUrl : pageUrl, `email${this.itoSuffix}`)
      ].join('\n\n');

      this.emailElement = document.createElement('a');
      this.emailElement.setAttribute('href', getMailToHref(subject, body));
      this.emailElement.setAttribute('target', '_blank');
      this.socialShareOverlay.element.querySelector('[data-social-scope="email"]')
        .appendChild(this.emailElement);

      getConfig('molFeSocialShareOverlay/recommendations')
        .then(({links, title} = {}) => {
          let text = links.map((linkItem) => [linkItem.title, linkItem.url].join('\n')).join('\n\n');

          this.emailElement.setAttribute('href', getMailToHref(subject, [
            `${body}\n`,
            title,
            text
          ].join('\n\n')));

          if (this.emailElement.href.length > 2000) {
            text = links.slice(0, 2).map((linkItem) => [linkItem.title, linkItem.url].join('\n')).join('\n\n');
            this.emailElement.setAttribute('href', getMailToHref(subject, [
              `${body}\n`,
              title,
              text
            ].join('\n\n')));
          }

          if (this.emailElement.href.length > 2000) {
            this.emailElement.setAttribute('href', getMailToHref(subject, body));
          }

          return null;
        }).catch(() => {});
    }
  }
}

export default Handler;
