/* eslint-disable complexity */
import {logger} from '@mol-fe/mol-fe-client-logger';
import getExtra, {SEPARATOR_MARGIN_HEIGHT} from './getExtra';

const getDoubleColumnSibling = (singleColumn) => {
  const DOUBLE_COLUMN_CLASS = 'alpha';

  return (
    singleColumn &&
    Array.from(singleColumn.parentNode.children)
      .find((element) => element && element.classList.contains(DOUBLE_COLUMN_CLASS))
  );
};

const puffItemNotAdFilter = (item) => item.classList && !item.classList.contains('puff-ad') && !item.classList.contains('native-ad');
const getDiffHeight = (first, second) => first.getBoundingClientRect().height - second.getBoundingClientRect().height;

let removedSecondPuffItems = [];

export const clearRemovedSecondPuffItems = () => {
  removedSecondPuffItems = [];
};

const processPuffs = () => {
  const puffs = document.querySelectorAll('.beta .femail .puff ul, .beta .tvshowbiz .puff ul');

  if (!puffs.length) {
    logger.info('No puffs found on the page');

    return;
  }

  const firstPuff = puffs[0];
  const secondPuff = puffs[1];
  const firstSingleColumn = firstPuff && firstPuff.closest('.beta');
  const secondSingleColumn = secondPuff && secondPuff.closest('.beta');
  const firstDoubleColumn = getDoubleColumnSibling(firstSingleColumn);
  const secondDoubleColumn = getDoubleColumnSibling(secondSingleColumn);

  if (!firstPuff || !firstSingleColumn || !firstDoubleColumn) {
    logger.info('No single/double/puff pairing found on the page');

    return;
  }

  const columnVsPuffDiff = getDiffHeight(firstDoubleColumn, firstSingleColumn);

  if (columnVsPuffDiff > 0) {
    if (secondDoubleColumn && secondSingleColumn && secondPuff) {
      let heightToFill = columnVsPuffDiff;
      let secondPuffItems = Array.from(secondPuff.children).filter(puffItemNotAdFilter);
      let heightOfNextItem = secondPuffItems[0].getBoundingClientRect().height + SEPARATOR_MARGIN_HEIGHT;

      while (secondPuffItems.length > 1 && heightToFill > heightOfNextItem) {
        const itemToMove = secondPuffItems[0];

        firstPuff.appendChild(itemToMove);

        secondPuffItems = Array.from(secondPuff.children).filter(puffItemNotAdFilter);
        heightToFill -= heightOfNextItem;
        heightOfNextItem = secondPuffItems[0].getBoundingClientRect().height + SEPARATOR_MARGIN_HEIGHT;
      }

      const secondExtra = getExtra(
        secondDoubleColumn,
        secondSingleColumn,
        secondPuff,
        0
      );

      secondExtra.forEach((element) => {
        if (element.parentNode) {
          removedSecondPuffItems.push(element);
          element.parentNode.removeChild(element);
        }
      });
    }
  } else {
    const firstExtra = getExtra(firstDoubleColumn, firstSingleColumn, firstPuff, 0);

    if (secondDoubleColumn && secondSingleColumn && secondPuff) {
      firstExtra.forEach((element) => {
        secondPuff.insertBefore(element, secondPuff.firstChild);
      });

      const secondExtra = getExtra(
        secondDoubleColumn,
        secondSingleColumn,
        secondPuff,
        firstSingleColumn.getBoundingClientRect().height - firstDoubleColumn.getBoundingClientRect().height
      );

      secondExtra.forEach((element) => {
        if (element.parentNode) {
          removedSecondPuffItems.push(element);
          element.parentNode.removeChild(element);
        }
      });
    } else {
      firstExtra.forEach((element) => {
        if (element.parentNode) {
          element.parentNode.removeChild(element);
        }
      });
    }
  }

  if (secondDoubleColumn && secondSingleColumn && secondPuff && removedSecondPuffItems.length) {
    let secondHeightDiff = getDiffHeight(secondDoubleColumn, secondSingleColumn);

    while (secondHeightDiff > 120 && removedSecondPuffItems.length > 0) {
      const itemToMove = removedSecondPuffItems.pop();

      secondPuff.appendChild(itemToMove);

      const imageEl = itemToMove.querySelector('img[data-src]');

      if (imageEl) {
        imageEl.src = imageEl.dataset.src;
      }

      secondHeightDiff -= itemToMove.getBoundingClientRect().height;
    }
  }
};

export default processPuffs;
