import {logger} from '@mol-fe/mol-fe-client-logger';

export const POLLING_INTERVAL = 30000;
const DEFAULT_LIMIT = 1000;
const POLLING_CAP = 15;
let pollingIteration = 1;
let pollingFailure = false;

export const beautifyValue = (value) => {
  if (isNaN(value)) {
    return null;
  }

  if (value < DEFAULT_LIMIT) {
    return value;
  }

  const formattedValue = parseFloat(value / 1000).toLocaleString('en-GB', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    roundingMode: 'halfCeil'
  });

  return formattedValue + 'k';
};

export const getCommentsAndUpdateCounter = async (
  url,
  articleId,
  counterElement
) => {
  const errorPrefix = '[mol-fe-openweb-comments] Unable to get comments count';

  try {
    const response = await fetch(url);
    const result = await response.json();

    if (
      result &&
      result.messages_count &&
      result.messages_count[articleId]
    ) {
      counterElement.innerHTML = beautifyValue(result.messages_count[articleId]);
    } else {
      pollingFailure = true;
      logger.error(errorPrefix, result);
    }
  } catch (error) {
    pollingFailure = true;
    logger.error(errorPrefix, error);
  }
};

export const pollForComments = async (url, articleId, counterElement, interval) => {
  if (!counterElement) {
    return;
  }

  await getCommentsAndUpdateCounter(url, articleId, counterElement);
  await new Promise((resolve) => setTimeout(resolve, interval));

  if (pollingFailure) {
    logger.error(`[mol-fe-openweb-comments] Abort polling comments count, unable to reach the API ${url}`);
  } else if (pollingIteration <= POLLING_CAP) {
    pollingIteration++;
    await pollForComments(url, articleId, counterElement, interval);
  }
};
