import {logger} from '@mol-fe/mol-fe-client-logger';

const postToFB = function (...args) {
  const DMS = window.DMS;

  if (DMS && DMS.Facebook && DMS.Facebook.postToFB) {
    DMS.Facebook.postToFB(...args);
  } else {
    logger.warn('DMS.Facebook.postToFB is not available');
  }
};

export default postToFB;
