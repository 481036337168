const replaceClass = function (element, classNameTo, classNameFrom) {
  const classList = element.classList;

  if (classList.contains(classNameFrom)) {
    classList.remove(classNameFrom);
  }

  if (!classList.contains(classNameTo)) {
    classList.add(classNameTo);
  }
};

export default replaceClass;
