import {logger} from '@mol-fe/mol-fe-client-logger';

const postToPinterest = function (...args) {
  const DMS = window.DMS;

  if (DMS && DMS.Pinterest && DMS.Pinterest.postToPinterest) {
    DMS.Pinterest.postToPinterest(...args);
  } else {
    logger.warn('DMS.Pinterest.postToPinterest is not available');
  }
};

export default postToPinterest;
